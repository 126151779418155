module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"gatsbyRemarkPlugins":[{"resolve":"/Users/timoshchuk/github/yanatimoshchuk.ru/node_modules/gatsby-remark-images","id":"194de7ad-4c7a-57e9-b023-6b26b284f68a","name":"gatsby-remark-images","version":"6.16.0","modulePath":"/Users/timoshchuk/github/yanatimoshchuk.ru/node_modules/gatsby-remark-images/index.js","pluginOptions":{"plugins":[],"maxWidth":650,"linkImagesToOriginal":false,"quality":80},"nodeAPIs":["pluginOptionsSchema"],"browserAPIs":["onRouteUpdate"],"ssrAPIs":[]}],"defaultLayouts":{},"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/Users/timoshchuk/github/yanatimoshchuk.ru","commonmark":false,"JSFrontmatterEngine":false,"engines":{}},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":650,"linkImagesToOriginal":false,"quality":80},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":650,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"tracedSVG":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-yandex-metrica/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"89321326","clickmap":false,"trackLinks":true,"accurateTrackBounce":true,"trackHash":true,"webvisor":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Яна Тимощук, психолог, сказкотерапевт в Краснодаре и по всему миру","short_name":"Яна Тимощук","start_url":"/","background_color":"#FBFBFB","theme_color":"#a580d4","display":"standalone","icon":"./src/images/favicon/favicon-96x96.png","crossOrigin":"use-credentials","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"b6dc119aa35675cb9a70c15a0722dae8"},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
